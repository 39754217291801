import React from 'react';
import { graphql, StaticQuery } from 'gatsby';


const HomeDescription = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                title
                subtitle
                description
                left_image {
                  source_url
                }
                right_image {
                  source_url
                }
                top_background_image {
                    source_url
                }
            }
        }
    }
    `} render={props => (
        <div className="frame" >
            <div className="home-description">
                <div className="top-background-image">
                    <img src={props.wordpressPage.acf.top_background_image.source_url} />
                </div>
                <div className="left">
                    <div className="line line1" data-aos="fade-up"></div>
                    <div className="subtitle" data-aos="fade-up" data-aos-delay="100">{ props.wordpressPage.acf.subtitle }</div>
                    <div className="title" data-aos="fade-up" data-aos-delay="200">{ props.wordpressPage.acf.title }</div>
                    <div className="line line2" data-aos="fade-up" data-aos-delay="300"></div>
                    <div className="description" data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.description}}></div>
                    <div className="left-image" data-aos="fade-up" data-aos-delay="500">
                        <div className="line line3" data-aos="fade-up" data-aos-delay="600"></div>
                        <img src={ props.wordpressPage.acf.left_image.source_url } alt="" />   
                    </div>
                </div>
                <div className="right" data-aos="fade-up">
                    <img src={ props.wordpressPage.acf.right_image.source_url } alt="" />    
                </div>
            </div>
        </div>
    ) } />
);

export default HomeDescription;