import React, { useState, useEffect, useRef } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import loader from "../../images/loader.gif";
// let OwlCarousel;
// if (typeof window !== 'undefined') {
//     OwlCarousel = require('react-owl-carousel');
// }
const OwlCarousel = React.lazy(() =>
    import('react-owl-carousel')
)
const isSSR = typeof window === "undefined"

const HomePageBanner = () => {

    // const [state, setState] = useState({
    //     isPopUpOpen: false
    // });

    // function openPopup() {
    //     setState({
    //         isPopUpOpen: true
    //     }); 
    // };

    // const node = useRef();

    // useEffect(() => {
    //     // add when mounted
    //     document.addEventListener("mousedown", handleClick);
    //     // return function to be called when unmounted
    //     return () => {
    //     document.removeEventListener("mousedown", handleClick);
    //     };
    //   }, []);

    //   const handleClick = e => {
    //     if(node.current.contains(e.target)) {
    //         // inside click
    //         return;
    //     }
    //     // outside click 
    //     setState({
    //         isPopUpOpen: false
    //     });
    // };

    return <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                banner_1 {
                    source_url
                }
                banner_2 {
                    source_url
                }
                banner_3 {
                    source_url
                }
                first_banner_title_1
                first_banner_title_2
                second_banner_title_1
                second_banner_title_2
                third_banner_title_1
                third_banner_title_2
                check_in_title
                check_out_title
                button_title
                trip_advisor_image {
                    source_url
                }
                popup_banner {
                    source_url
                }
                popup_image {
                    source_url
                }
                popup_pre_heading
                popup_discount
                popup_discount_text
                popup_claim_text
                popup_post_heading
                popup_booknow_button
                popup_buooknow_button_link
            }
        }
    }
    `} render={props => (
         <div className="home-banner banner">
            {!isSSR && (
                <React.Suspense fallback={<div className="preloader"><img src={ loader } /></div>}>
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        dots = { false }
                        autoplay
                        autoplayTimeout = { 8000 }
                        items = {1}
                        animateOut = { 'fadeOut' }
                        animateIn = { 'fadeIn' }
                        mouseDrag = { false }
                        touchDrag = { false }
                    >
                    <div className="item-outer">
                        <div className="item" style={{backgroundImage: 'url(' + props.wordpressPage.acf.banner_1.source_url + ')'}}></div>
                        <div className="banner-headings banner-heading-1">
                            <div className="banner_title_1">
                                { props.wordpressPage.acf.first_banner_title_1 }
                            </div>
                            <div className="banner_title_2">
                                { props.wordpressPage.acf.first_banner_title_2 }
                            </div>
                        </div>
                    </div>
                    <div className="item-outer">
                        <div className="item" style={{backgroundImage: 'url(' + props.wordpressPage.acf.banner_2.source_url + ')'}}></div>
                        <div className="banner-headings banner-heading-2">
                            <div className="banner_title_1">
                                { props.wordpressPage.acf.second_banner_title_1 }
                            </div>
                            <div className="banner_title_2">
                                { props.wordpressPage.acf.second_banner_title_2 }
                            </div>
                        </div>
                    </div>
                    <div className="item-outer">
                        <div className="item" style={{backgroundImage: 'url(' + props.wordpressPage.acf.banner_3.source_url + ')'}}></div>
                        <div className="banner-headings banner-heading-3">
                            <div className="banner_title_1">
                                { props.wordpressPage.acf.third_banner_title_1 }
                            </div>
                            <div className="banner_title_2">
                                { props.wordpressPage.acf.third_banner_title_2 }
                            </div>
                        </div>
                    </div>
                    </OwlCarousel>
                </React.Suspense>
            )}
            <div className="banner-bottom">
                <div className="search-and-tripadvisor">
                    <div className="search-form">

                        <div className="check-in">
                            <i className="material-icons">date_range</i>
                            <input type="text" id="dt1" placeholder={ props.wordpressPage.acf.check_in_title } />
                        </div>
                        <div className="check-out">
                            <i className="material-icons">date_range</i>
                            <input type="text" id="dt2" placeholder={ props.wordpressPage.acf.check_out_title } />
                        </div>
                        <div className="search">
                        <button id="calsubmit">{ props.wordpressPage.acf.button_title }</button>
                    </div>
                    </div>
                    <div className="tripadvisor">
                        <a href="https://www.tripadvisor.in/Hotel_Review-g12406123-d1018883-Reviews-138_Marine_Beachfront_Guesthouse-Sandbaai_Hermanus_Overstrand_Overberg_District_West.html" target="_blank">
                            <img src={ props.wordpressPage.acf.trip_advisor_image.source_url } alt="tripadvisor" />
                        </a>
                    </div>
                </div>
            </div>

         </div>
    ) } />
};

export default HomePageBanner;