import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import "../css/material-icons.css"
import "../css/jquery-ui.css"

import React, { Component } from "react"
import DefaultTemplate from "../templates/default"
import HomePageBanner from "../components/home/banner"
import HomeDescription from "../components/home/description"
import HomeAccommodation from "../components/home/accommodation"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Index extends Component {
    constructor(props){
        super(props);
    }
    componentDidMount(){
    AOS.init({
        duration : 2000,
        once: true
    })
    }
    render(){
        return <DefaultTemplate> 
                    <HomePageBanner />
                    <HomeDescription />
                    <HomeAccommodation />
                </DefaultTemplate>
    }
}
   
export default Index

// export default ({pageContext}) => (
//     <DefaultTemplate> 
//        <HomePageBanner />
//        <HomeDescription />
//        <HomeAccommodation />
//     </DefaultTemplate>
// );